import service from './index'

const resourcePath = '/resource'

export default {
    uploadImage(data) {
        return service.apiAuth.post(`${resourcePath}/upload`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    uploadFileSignature (data) {
        return service.apiAuth.post(`${resourcePath}/upload-with-watermark`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    }
}
<template>
  <b-row class="mb-2">
    <b-card class="w-100 m-2">
      <div class="d-flex align-items-center justify-content-between">
       <div class="d-flex back-arrow" v-if="is_arrow_back" @click="backPage()">
         <i class="fas fa-arrow-left text-primary mr-2" style="font-size: 20px"/>
         <h4 class="card-title mb-0 text-primary">{{ topic }}</h4>
       </div>
        <div v-else>
          <h4 class="card-title mb-0 text-primary">{{ topic }}</h4>
        </div>
        <div v-if="is_button">
          <b-button class="m-0" variant="primary" @click="backPage()">
            <i class="fas fa-arrow-left"></i>
            {{ $t(`${button_text}`) }}
          </b-button>
        </div>
        <div v-if="is_verify_button" class="d-flex verify-box-button">
          <div class="text-primary mt-2 mr-3">
            {{$t('account.verify_account.account_type')}} : {{ verifyInfo?.customer_type === 'personal' ? $t('account.verify_account.personal') : $t('account.verify_account.juristic')}}
          </div>
          <b-button class="m-0 btn-verify-success-account" @click="getVerifyInfoAccount($event)">
            <div class="d-flex">
              <img src="@/assets/images/icon/new_releases.svg" alt="ar-on-you">
              <div class="ml-2 mt-1">{{$t('account.verify_account.verified')}}</div>
            </div>
          </b-button>
        </div>
        <div v-if="tab_active" class="text-secondary text-bold mr-5">
          {{tab_active.tab}} / {{tab_active.total}}
        </div>
      </div>
    </b-card>
    <div v-if="is_verify_button">
      <verify-info :data="verifyInfo" :user-info="userInfo"/>
    </div>
  </b-row>
</template>

<script>
import profileApi from "@/repository/profileApi";
import VerifyInfo from "@/views/account/verify/verifyInfo.vue";

export default {
  name: "topicComponent",
  components: {VerifyInfo},
  props: {
    topic: String,
    is_button: Boolean,
    button_text: String,
    url: String,
    name: String,
    is_arrow_back: Boolean,
    is_verify_button: Boolean,
    tab_active: Object
  },
  data () {
    return {
      verifyInfo: {},
    }
  },
  computed: {
    userInfo () {
      return  this.$store.getters["profile/getProfile"]
    }
  },
  mounted() {
    if (this.userInfo?.identity_verify_status === 'verified') {
      this.verifyInfoAccount()
    }
  },
  methods: {
    backPage() {
      if (this.url) {
        this.$router.push(`${this.url}`).catch(() => {
        })
      } else if (this.name) {
        this.$router.push({name: `${this.name}`}).catch(() => {
        })
      }
    },
    verifyInfoAccount () {
      profileApi.getVerifyInfo().then(resp => {
        if (!!resp) {
          this.verifyInfo = resp
        }
      }).catch(err => {
        throw err
      })
    },
    getVerifyInfoAccount (e) {
      if (e) {
        this.$bvModal.show('modal-verify-info')
      }
    }
  }

}
</script>
<style scoped>
.back-arrow:hover {
  cursor: pointer;
}
.verify-box-button {
  vertical-align: middle;
}
.btn-verify-success-account {
  background: linear-gradient(90deg, #02A4A4 0%, #00E3BA 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 0;
  padding: .4rem 2rem .4rem 2rem;
  vertical-align: middle;
}

.btn-verify-success-account:hover, .btn-verify-success-account:focus {
  background: linear-gradient(90deg, #02A4A4 0%, #00E3BA 100%) !important;
}
</style>
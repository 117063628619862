<template>
  <div class="mb-3">
    <div class="d-flex">
      <div class="d-flex">
        <h5 class="mr-2">{{ title }}</h5>
        <strong v-show="required" style="color: red">*</strong>
      </div>
      <div class="info-content">{{ infoContent }}</div>
    </div>
    <div v-if="isActiveVat" class="is-active-vat-box">
      <b-row>
        <b-col cols="1">
          <b-form-checkbox  name="check-button" switch/>
        </b-col>
        <b-col>
          <div>
            <div>จดภาษีมูลค่าเพิ่ม VAT 7%</div>
            <div class="font-size-10">หากบริษัทของท่านมีการจดทะเบียนภาษีมูลค่าเพิ่ม (มีการยื่น ภ.พ. 20) กรุณาแนบเอกสาร ภ.พ. 20 เพื่อรักษาสิทธิของท่าน</div>
            <div class="font-size-10">ในการทำธุรกรรมการเงินกับ SMS2PRO ระบบจะคำนวณภาษีมูลค่าเพิ่ม VAT 7% ให้ท่าน</div>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-collapse v-model="showPreview" >
      <div class="example-img-box">
        <i class="fas fa-times close"
          v-if="closeIcon"
          @click="clearImage()"/>
        <img :src="previewImage" height="100%" @click="fullScreen(previewImage, embed_type)" alt="preview">
      </div>
    </b-collapse>
    <div class="upload-file-signature-box">
      <div v-if="previewImage" class="preview-content-link d-flex">
        <a  :href="link" target="_blank"> {{ changeFormatStr(link) }}</a>
        <div class="ml-2 img-content">
          <img src="@/assets/images/icon/check_success.svg" width="18" alt="check">
        </div>
      </div>
      <div v-else class="upload-content">
        <div v-if="!is_loading" class="d-flex">
          {{ textUploadContent }}
          <div class="ml-2 img-content">
            <img src="@/assets/images/icon/upload-file-content.svg" width="18" alt="check">
          </div>
        </div>
        <div v-else class="d-flex">
          <strong class="mr-2"> {{ $t('loading') }}</strong>
          <b-spinner small v-if="is_loading"/>
        </div>

      </div>
      <input id="inputUploadFileSignature" ref="inputUploadFileSignature" :accept="accept || 'image/*'" type="file"
             @change="handleFileChange" :hidden="previewImage && is_loading">
      <p class="mb-3 mt-2 error-text" v-if="fileToLarge || fileInvalid">
        {{showError}}
      </p>
    </div>
    <!--  set img full screen     -->
    <b-modal id="full-screen-modal" :visible="is_fullscreen" hide-footer hide-header no-close-on-esc
             no-close-on-backdrop size="lg">
      <div class="close-icon-full" @click="closeFullScreen">
        <i class="fas fa-times"/>
      </div>
      <div class="box-full-screen-img">
        <embed :src="img_full" height="100%" width="100%"
               :type="embed_type_file">
      </div>
    </b-modal>
  </div>
</template>
<script>
import resourceApi from "@/repository/resourceApi";
import common from "@/common/functions";

export default {
  name: "uploadFileSignatureComponent",
  props: {
    title: {
      type: String
    },
    infoContent: {
      type: String
    },
    textUploadContent: {
      type: String
    },
    closeIcon: {
      default: true,
      required: false
    },
    required: {
      type: Boolean,
      request: false,
      default: false
    },
    accept: {
      type: String,
      required: false,
    },
    isActiveVat: {
      type: Boolean,
      request: false,
      default: false
    },
    value: {
      required: false,
    },
  },
  data() {
    return {
      fileInvalid:false,
      fileToLarge: false,
      previewImage: null,
      embed_type: '',
      link:null,
      is_fullscreen: false,
      img_full: null,
      is_loading: false,
      embed_type_file: ''
    }
  },
  computed:{
    showError(){
      if (this.fileInvalid){
        return this.$t('setting.multiple.err.notSupport')
      }else if(this.fileToLarge){
        return  this.$t('setting.multiple.err.maxSize')
      }
    },
    showPreview() {
      if (this.previewImage) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      const is_not_pdf = file.type !== 'application/pdf'
      const accept = ['application/pdf', 'image/png', 'image/jpeg'];
      const fileSizeInMB = file.size / (1024 * 1024);
      this.fileToLarge = false
      this.fileInvalid = false
      if (Number(fileSizeInMB.toFixed(2)) > 10 && is_not_pdf) {
        common.resizeImage(file, (resizedDataURL) => {
          const buffer = resizedDataURL.split(',')
          let mapData = {
            mimetype: file?.type,
            buffer: buffer[1],
          }
          this.is_loading = true
          resourceApi.uploadFileSignature(mapData).then((response) => {
            this.previewImage = response?.linkPdfImage ? response?.linkPdfImage : response?.link
            this.link = response?.link
            this.embed_type = response?.type
            this.$emit('input', response?.link)
          }).catch(err => {
            if (err.response.status === 413) {
              this.fileToLarge = true
            }
            throw  err
          }).finally(() => {
            this.is_loading = false
          })
        });
      } else if (accept.includes(file.type)) {
        const reader = new FileReader();
        this.is_loading = true
        reader.onloadend = () => {
          let base64 = reader.result.split(',')
          let type = base64[0].split(':')
          type = type[1].split(';')
          type = type[0]
          base64 = base64[1]
          let data = {
            mimetype: type,
            buffer: base64,
          }
          this.is_loading = true
          resourceApi.uploadFileSignature(data).then((response) => {
            this.previewImage = response?.linkPdfImage ? response?.linkPdfImage : response?.link
            this.link = response?.link
            this.embed_type = response?.type
            this.$emit('input', response?.link)
          }).catch(err => {
            if (err.response.status === 413) {
              this.fileToLarge = true
            }
            throw  err
          }).finally(() => {
            this.is_loading = false
          })
        };
        reader.readAsDataURL(file);
      } else {
        this.fileInvalid = true
      }
    },
    changeFormatStr(text) {
      let str = text ? text?.substring(0, 50) : '-'
      return str.length <= 70 && str.length > 1 ? str + '...' : str
    },
    closeFullScreen() {
      this.is_fullscreen = false
      this.img_full = null
    },
    fullScreen(img, type) {
      this.is_fullscreen = true
      this.img_full = img
      this.embed_type_file = type
    },
    clearImage() {
      this.previewImage = null
      this.embed_type = null
      this.$refs.inputUploadFileSignature.value = ''
      this.$emit('input', null)
    },
  },
  watch: {
    'value'(newData) {
      this.previewImage = newData
      this.link = newData
    }
  }
}
</script>
<style scoped lang="scss">
.info-content {
  font-size: 12px;
  color: var(--info);
  margin-top: 2px;
  margin-left: 5px;
}

.upload-file-signature-box {
  padding: 0;
  border: 2px dashed var(--primary-color);
  box-sizing: border-box;
  border-radius: 5px;
  height: 3rem;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  vertical-align: middle;
}

.upload-file-signature-box::v-deep > input[type="file"]::-webkit-file-upload-button {
  padding: 5px 12px;
  color: #fff;
  font-size: 1rem;
  transition: all .4s;
  cursor: pointer;
  float: right;
  background: #64ADDA;
  border-radius: 5px;
  height: 3rem;
  margin-right: 0;
  width: 100%;
  border: 1px solid #64ADDA;
}

.upload-file-signature-box::v-deep > input[type="file"] {
  position: relative;
  padding: 0;
  background: #FFFFFF;
  border: 2px dashed var(--primary-color);
  box-sizing: border-box;
  border-radius: 5px;
  height: 3rem;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  left: 0;
}

.upload-content {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
}

.example-img-box {
  background: rgba(231, 252, 245, 1);
  box-sizing: border-box;
  border-radius: 5px;
  height: 243px;
  text-align: center;
  position: relative;
  overflow: hidden;

}

.example-img-box:hover {
  background-color: rgba(var(--primary-color-rgb), 0.5);
  cursor: zoom-in;
}

.example-img-box::v-deep img {
  object-fit: contain;
  vertical-align: middle;
  text-align: center;
}

.example-img-box-preview {
  background: rgba(231, 252, 245, 1);
  box-sizing: border-box;
  border-radius: 5px;
  height: 243px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.preview-content-link {
  margin-top: .7rem;
  color: var(--primary-color);
  text-align: center;
  justify-items: center;
  vertical-align: middle;
  justify-content: center;
}

.example-img-box > .close {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgb(149, 0, 0);
  position: absolute;
  top: 10px;
  right: 19px;
  text-align: center;
  line-height: 24px;
  z-index: 10;
  cursor: pointer;
  color: #ffffff;
  opacity: 1;
}

.box-full-screen-img {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  height: 90vh;
  overflow: hidden;

  embed {
    width: 100%;
    object-fit: contain;
    margin-top: 2em;
  }
}

.error-text {
  color: red;
  font-size: 12px;
  word-break: break-all;
}

::v-deep #full-screen-modal > .modal-dialog {
  max-width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
}

::v-deep #full-screen-modal > .modal-dialog > .modal-content {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  margin: 0;
  border-radius: 0;
  background: #0c0d10;
}

.box-full-screen-img {
  display: flex;
  vertical-align: middle;
  justify-content: center;

  img {
    height: 90vh;
    width: 100%;
    object-fit: contain;
    margin-top: 2em;
  }
}
.close-icon-full {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgb(149, 0, 0);
  position: absolute;
  top: 15px;
  right: 19px;
  text-align: center;
  line-height: 24px;
  z-index: 1;
  cursor: pointer;
  color: #ffffff;
  opacity: 1;
}

.close-icon-full:hover {
  background-color: rgb(108, 94, 94);
  opacity: 1;
}
.is-active-vat-box {
  border-radius: 10px;
  background: #FFF6E8;
  padding: 1rem;
}
</style>
<template>
  <div>
    <div class="d-flex" :class="iconTab ? 'mb-2' : ''">
      <div class="mt-2" v-if="label">
        <label class="label-for-input text-secondary mb-1">{{ label }}</label>
        <p v-if="required" class="req-text">*</p>
      </div>
      <div v-if="iconTab" class="d-flex"></div>
    </div>
    <div
        :class="`${className || ''} upload-file-box`"
        v-if="!previewImage && checkObj(value)"
    >
      <div style="margin: 0 auto;">
        <img src="~@/assets/images/icon/add-img.png" alt="add-img"/>
        <div>
          <label style="cursor: pointer;" class="m-2">
            {{ $t('common.uploadImg') }}
          </label>
        </div>
      </div>
      <input type="file" ref="fileComponent" @change="handleFileChange" accept="image/*" multiple/>

      <div :class="`${errorText || ''} error-text`">
        <sup v-if="limitFileError">*</sup>
        {{ limitFileError }}
      </div>
    </div>
    <div v-else :class="`${previewClassName || ''} preview-file-box`">
      <img
          v-if="activeIconTab !== 'video'"
          :src="previewImage"
          alt="ไม่มีไฟล์"
      />
      <span
          style="cursor: pointer;"
          class="far fa-times-circle text-danger"
          :class="`${iconCloseClass || ''} top-right`"
          @click="clearImage"
      />
    </div>
  </div>
</template>

<script>
import resourceApi from '@/repository/resourceApi'

const limitSize = 25000000
export default {
  name: 'uploadImageComponent',
  props: {
    label: {
      type: String,
      required: false,
    },
    subLabel: {
      type: String,
      required: false,
    },
    value: {
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
    previewClassName: {
      type: String,
      required: false,
    },
    iconCloseClass: {
      type: String,
      required: false,
    },
    sizeFile: {
      type: Number,
      required: false,
    },
    accept: {
      type: String,
      required: false,
    },
    errorText: {
      type: String,
      required: false,
    },
    iconTab: {
      type: Boolean,
      required: false,
    },
    activeIconTab: {
      type: String,
      required: false,
    },
    sizeFileKB: {
      type: Number,
      required: false,
    },
    imgUrl: {
      type: String,
      default: null,
    },
  },
  computed: {
    previewImage: {
      get() {
        if (this.imgUrl !== null) {
          return this.imgUrl
        } else {
          if (_.isObject(this.value)) {
            return null
          } else {
            return this.value
          }
        }
      },
      set() {
        return this.value
      }
    }
  },
  data() {
    return {
      limitFileError: '',
    }
  },

  emits: ['input'],
  methods: {
    handleFileChange(e) {
      let file = e.target.files
      if (file[0].size > limitSize) {
        this.$emit('overSize')
      } else if (file && file[0]) {
        const reader = new FileReader()
        reader.onloadend = (e) => {
          this.previewImage = e.target.result
          let base64 = reader.result.split(',')
          let type = base64[0].split(':')
          type = type[1].split(';')
          type = type[0]
          base64 = base64[1]
          let data = {
            mimetype: type,
            buffer: base64,
          }
          resourceApi.uploadImage(data).then((response) => {
            this.$emit('input', response.link)
          })
        }
        reader.readAsDataURL(file[0])
      }
    },
    clearImage() {
      this.previewImage = ''
      this.$emit('input', null)
      this.limitFileError = ''
      this.$refs.fileComponent.value = null
    },
    checkObj(dataValue) {
      return _.isObject(dataValue) ? Object.keys(dataValue).length === 0 : true
    },
  },

}
</script>

<style scoped>
.upload-file-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0;
  border: 1px dashed #6c757d;
  box-sizing: border-box;
  border-radius: 5px;
  width: 220px;
  max-width: 100%;
  height: 220px;
  cursor: pointer;
  background: #d6d6d6 center center;
  text-align: center;

}

.preview-file-box {
  position: relative;
}

.preview-file-box > img {
  border: 2px dashed #d6d6d6;
  padding: 2px;
  width: 100%;
  height: 220px;
  object-fit: contain;
  background: rgba(214, 214, 214, 0.30);
  border-radius: 5px;
}

.upload-file-box > input[type='file']::-webkit-file-upload-button {
  padding: 5px 12px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.4s;
  cursor: pointer;
  float: right;
  background: #64adda;
  border-radius: 5px;
  height: 39px;
  margin-right: 0px;
  border: 1px solid #64adda;
}

.upload-file-box > input[type='file'] {
  position: absolute;
  padding: 0;
  background: #ffffff;
  border: 2px dashed #d6d6d6;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 220px;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.upload-icon {
  color: #64adda;
  align-items: center;
  cursor: pointer;
  margin-top: 4rem;
  height: 60px;
  width: 50px;
}

.label-upload {
  font-size: 16px;
  text-align: left;
  margin-bottom: 2px;
  color: #a9a9a9;
  cursor: pointer;
}

.content-upload {
  font-size: 19px;
  text-align: center;
  margin-bottom: 2px;
  color: #a9a9a9;
  cursor: pointer;
  margin-top: 0rem;
  padding: 20px;
}

.top-right {
  position: absolute;
  top: 1rem;
  right: 0.75rem;
  background-color: unset;
}

.add-img-icon-svg-upload {
  margin: 5px !important;
  cursor: pointer !important;
  color: #25282b !important;
}

.add-img-icon-svg-upload:hover {
  margin: 5px !important;
  cursor: pointer !important;
  color: #64adda !important;
}

.add-img-icon-svg-upload-active {
  margin: 5px !important;
  cursor: pointer !important;
  color: #64adda !important;
}
</style>

<template>
  <div class="w-100">
    <div :class="iconTab ? 'mb-2' : ''">
      <b-row>
        <b-col class="col-auto" style="padding-right: 5px!important;">
          <label class="label-for-input text-green" v-if="label">{{ label }} </label>
        </b-col>
        <b-col v-show="required" align="left" class="col-2" style="padding-left: 5px!important;">
          <strong style="color: red">*</strong>
        </b-col>
      </b-row>
      <div v-if="iconTab" class="d-flex"></div>
    </div>
    <div
        :class="`${className || ''} upload-file-box`"
        v-if="!previewImage && checkObj(value)"
    >
      <div style="margin: 0 auto;" v-if="!loading" class="flex-column">
        <img :src="imgAdd" alt=""/>
        <div>
          <label style="cursor: pointer;" class="m-2">
            {{ $t(textUpload) }}
          </label>
        </div>
      </div>
      <b-spinner v-if="loading"></b-spinner>
      <input :id="id" type="file" @change="handleFileChange" :accept="accept || 'image/*'"/>
      <div :class="`${errorText || ''} error-text`">
        <sup v-if="limitFileError">*</sup>
        {{ limitFileError }}
      </div>
    </div>
    <div v-else :class="`${previewClassName || ''} preview-file-box`">
      <img
          @click="fullScreen(previewImage)"
          v-if="activeIconTab !== 'video'&& !is_pdf"
          :src="previewImage"
          alt="ไม่มีไฟล์"
      />
      <div v-else-if="activeIconTab !== 'video' && is_pdf" @click="fullScreen(previewImage)" class="iframe-container">
        <div class="overlay"></div>
        <iframe :src="previewImage">
        </iframe>
      </div>
      <span
          v-if="closeIcon"
          style="cursor: pointer;"
          class="far fa-times-circle text-danger"
          :class="`${iconCloseClass || ''} top-right`"
          @click="clearImage"
      />
    </div>
    <b-modal id="full-screen-modal-preview-img-pdf" :visible="is_fullscreen" hide-footer hide-header no-close-on-esc
             no-close-on-backdrop>
      <div class="close-icon-full" @click="closeFullScreen">
        <i class="fas fa-times"/>
      </div>
      <div class="box-full-screen-img">
        <img :src="img_full" alt="full-img" v-if="!is_pdf">
        <div class="iframe-preview" v-else>
          <iframe :src="img_full" class="iframe"></iframe>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import resourceApi from '@/repository/resourceApi'
import {parseInt} from "lodash";
import common from "@/common/functions";

const limitSize = 25000000
export default {
  name: 'previewImgOrPdfComponent',
  props: {
    label: {
      type: String,
      required: false,
    },
    subLabel: {
      type: String,
      required: false,
    },
    value: {
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
    previewClassName: {
      type: String,
      required: false,
    },
    iconCloseClass: {
      type: String,
      required: false,
    },
    sizeFile: {
      type: Number,
      required: false,
    },
    accept: {
      type: String,
      required: false,
    },
    errorText: {
      type: String,
      required: false,
    },
    iconTab: {
      type: Boolean,
      required: false,
    },
    activeIconTab: {
      type: String,
      required: false,
    },
    sizeFileKB: {
      type: Number,
      required: false,
    },
    imgUrl: {
      type: String,
      default: null,
    },
    id: {
      type: String
    },
    imgAdd: {
      type: String,
      required: false,
      default: require('@/assets/images/icon/add-img.png'),
    },
    textUpload: {
      type: String,
      required: false,
      default: 'common.uploadImg',
    },
    closeIcon: {
      default: true,
      required: false
    },
  },
  computed: {
    previewImage: {
      get() {
        if (this.imgUrl !== null) {
          this.is_pdf = this.imgUrl.includes('.pdf')
          return this.imgUrl
        } else {
          if (_.isObject(this.value)) {
            return null
          } else {
            return this.value
          }
        }
      },
      set() {
        return this.value
      }
    }
  },
  data() {
    return {
      is_pdf: false,
      loading: false,
      limitFileError: '',
      is_fullscreen: false,
      img_full: null
    }
  },
  emits: ['input'],
  methods: {
    fullScreen(img) {
      this.is_fullscreen = true
      this.img_full = img
    },
    closeFullScreen() {
      this.is_fullscreen = false
      this.img_full = null
    },
    handleFileChange(e) {
      let file = e.target.files
      const actualFile = file[0]
      const is_not_pdf = actualFile.type !== 'application/pdf'
      const accept = ['application/pdf', 'image/png', 'image/jpeg'];
      const fileSizeInMB = actualFile.size / (1024 * 1024);
      this.loading = true
      this.limitFileError = ''
      if (Number(fileSizeInMB.toFixed(2)) > 10 && is_not_pdf) {
        common.resizeImage(actualFile, (resizedDataURL) => {
          const buffer = resizedDataURL.split(',')
          let mapData = {
            mimetype: file?.type,
            buffer: buffer[1],
          }
          resourceApi.uploadFileSignature(mapData).then((response) => {
            this.$emit('input', response?.link)
          }).catch(err => {
            if (err.response.status === 413) {
              this.limitFileError = this.$t('setting.multiple.err.maxSize')
              this.$emit('overSize')
            }
            throw  err
          }).finally(() => {
            this.loading = false
          })
        });

      } else if (file && actualFile && accept.includes(actualFile.type)) {
        const reader = new FileReader()
        reader.onloadend = (e) => {
          this.previewImage = e.target.result
          let base64 = reader.result.split(',')
          let type = base64[0].split(':')
          type = type[1].split(';')
          type = type[0]
          base64 = base64[1]
          let data = {
            mimetype: type,
            buffer: base64,
          }
          resourceApi.uploadFileSignature(data).then((response) => {
            this.$emit('input', response?.link)
          }).catch(err => {
            if (err.response.status === 413) {
              this.limitFileError = this.$t('setting.multiple.err.maxSize')
              this.$emit('overSize')
            }
            throw  err
          }).finally(() => {
            this.loading = false
          })
        }
        reader.readAsDataURL(actualFile)
      } else {
        this.loading = false
        this.limitFileError = this.$t('setting.multiple.err.notSupport')
        this.$emit('overSize')
      }
    },
    clearImage() {
      this.previewImage = ''
      this.$emit('input', null)
      this.limitFileError = ''
    },
    checkObj(dataValue) {
      return _.isObject(dataValue) ? Object.keys(dataValue).length === 0 : true
    },
    typeMedia(type) {
      this.$emit('media', type)
    },
  },

}
</script>

<style scoped>
.upload-file-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 0;
  border: 1px dashed #6c757d;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 220px;
  cursor: pointer;
  background: #ffffff center center !important;
  text-align: center;

}

.preview-file-box {
  position: relative;
}

.preview-file-box > img, .preview-file-box > .iframe-container {
  border: 2px dashed #d6d6d6;
  padding: 2px;
  width: 100%;
  height: 220px;
  object-fit: contain;
  background: rgba(214, 214, 214, 0.30);
  border-radius: 5px;
}

.iframe-container {
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: zoom-in;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}


.upload-file-box > input[type='file']::-webkit-file-upload-button {
  padding: 5px 12px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.4s;
  cursor: pointer;
  float: right;
  background: #64adda;
  border-radius: 5px;
  height: 39px;
  margin-right: 0px;
  border: 1px solid #64adda;
}

.upload-file-box > input[type='file'] {
  position: absolute;
  padding: 0;
  background: #ffffff;
  border: 2px dashed #d6d6d6;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 220px;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.upload-icon {
  color: #64adda;
  align-items: center;
  cursor: pointer;
  margin-top: 4rem;
  height: 60px;
  width: 50px;
}

.label-upload {
  font-size: 16px;
  text-align: left;
  margin-bottom: 2px;
  color: #a9a9a9;
  cursor: pointer;
}

.content-upload {
  font-size: 19px;
  text-align: center;
  margin-bottom: 2px;
  color: #a9a9a9;
  cursor: pointer;
  margin-top: 0rem;
  padding: 20px;
}

.top-right {
  position: absolute;
  top: 1rem;
  right: 0.75rem;
  background-color: unset;
}

.add-img-icon-svg-upload {
  margin: 5px !important;
  cursor: pointer !important;
  color: #25282b !important;
}

.add-img-icon-svg-upload:hover {
  margin: 5px !important;
  cursor: pointer !important;
  color: #64adda !important;
}

.add-img-icon-svg-upload-active {
  margin: 5px !important;
  cursor: pointer !important;
  color: #64adda !important;
}

.wrapper-box {
  display: inline-block;
  margin-right: 10px;
}

.content-box {
  display: inline-flex;
  flex-direction: unset;
  flex-flow: row wrap;
  border-radius: 10px;
  /*border: 2px dashed #D6D6D6;*/
  vertical-align: middle;
  padding: 10px;
  text-align: center;
  justify-content: flex-start;
  width: 100%;
}

.preview-file-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px;
}

.preview-file-box > .box {
  width: 200px;
  padding: 0 10px;
}

.preview-file-box > .box > .close {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgb(149, 0, 0);
  position: absolute;
  top: 10px;
  right: 19px;
  text-align: center;
  line-height: 24px;
  z-index: 10;
  cursor: pointer;
  color: #ffffff;
  opacity: 1;
}

.preview-file-box > .box > .close:hover {
  background-color: rgb(108, 94, 94);
  opacity: 1;
}

.preview-file-box > .box > img {
  border: 2px dashed #D6D6D6;
  width: 100%;
  height: 220px;
  object-fit: cover;
  background: #FFFFFF center center;
  border-radius: 5px;
}

.preview-file-box > .box > .doc-box {
  border: 2px dashed #D6D6D6;
  width: 100%;
  height: 220px;
  object-fit: cover;
  background: #FFFFFF center center;
  border-radius: 5px;
  cursor: pointer;
}

.preview-file-box > .box > .doc-box > .doc-box-content {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preview-file-box:hover img {
  opacity: .5;
  cursor: zoom-in;
}

@-webkit-keyframes flash {
  0% {
    opacity: .4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: .4;
  }
  100% {
    opacity: 1;
  }
}


.content-upload-full {
  font-size: 15px;
  text-align: center;
  margin-bottom: 2px;
  color: #A9A9A9;
  cursor: pointer;
  margin-top: 2rem;
  padding: 20px;
  vertical-align: middle;

}

.label-upload {
  font-size: 16px;
  text-align: left;
  margin-bottom: 2px;
  color: #A9A9A9;
  cursor: pointer;
}

.content-upload {
  font-size: 15px;
  text-align: center;
  margin-bottom: 2px;
  color: #A9A9A9;
  cursor: pointer;
  margin-top: 0rem;
  padding: 20px;
  vertical-align: middle;

}

.error-text {
  color: red;
  font-size: 12px;
  word-break: break-all;
}

::v-deep #full-screen-modal-preview-img-pdf > .modal-dialog {
  max-width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
}

::v-deep #full-screen-modal-preview-img-pdf > .modal-dialog > .modal-content {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  margin: 0;
  border-radius: 0;
  background: #0c0d10;
}

.box-full-screen-img {
  display: flex;
  vertical-align: middle;
  justify-content: center;

  .iframe-preview {
    height: 100vh;
    width: 100%;
    margin-top: 48px;

    .iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  img {
    height: 90vh;
    width: 100% !important;
    object-fit: contain;
    margin-top: 2em;
  }
}

.close-icon-full {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgb(149, 0, 0);
  position: absolute;
  top: 15px;
  right: 19px;
  text-align: center;
  line-height: 24px;
  z-index: 1;
  cursor: pointer;
  color: #ffffff;
  opacity: 1;
}

.close-icon-full:hover {
  background-color: rgb(108, 94, 94);
  opacity: 1;
}

</style>

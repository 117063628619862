<template>
  <div>
    <b-modal id="modal-verify-info" size="lg" hide-footer no-close-on-backdrop
             no-close-on-esc>
      <template #modal-header="{ close }">
        <h5 class="text-primary">{{ $t('account.verify_account.identity_details') }}</h5>
        <i class="fas fa-times hover-icon action-button" @click="closePopup"/>
      </template>
      <div>
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <div class="info-account">
              <div>
                <h5>{{ $t('account.verify_account.identity_info_and_docs') }}</h5>
              </div>
              <hr class="hr-dash"/>
              <div>
                <div class="d-flex">
                  <div class="text-bold">{{ $t('account.verify_account.account_type') }} :</div>
                  <div class="ml-2">{{
                      data?.customer_type === 'personal' ? $t('account.verify_account.personal') : $t('account.verify_account.juristic')
                    }}
                  </div>
                </div>
              </div>
              <hr class="hr-dash"/>
              <div v-if="data?.customer_type === 'personal'">
                <b-row align-h="between">
                  <b-col class="text-left text-bold">{{ $t('account.verify_account.full_name') }}</b-col>
                  <b-col class="text-right">{{ data?.first_name }} {{ data?.last_name }}</b-col>
                </b-row>
                <b-row align-h="between">
                  <b-col class="text-left text-bold">{{ $t('account.verify_account.national_id') }}</b-col>
                  <b-col class="text-right">{{ data?.tax_id }}</b-col>
                </b-row>
                <b-row align-h="between">
                  <b-col cols="2" class="text-left text-bold">{{ $t('subscription.checkout.address') }}</b-col>
                  <b-col class="text-right">{{ changeFormatAddress(data?.address) }}</b-col>
                </b-row>
              </div>
              <div v-if="data?.customer_type === 'juristic'">
                <b-row align-h="between">
                  <b-col class="text-left text-bold">{{ $t('account.verify_account.company_name') }}</b-col>
                  <b-col class="text-right">{{ data?.company_name }}</b-col>
                </b-row>
                <b-row align-h="between">
                  <b-col class="text-left text-bold">{{ $t('account.verify_account.tax_id') }}</b-col>
                  <b-col class="text-right">{{ data?.tax_id }}</b-col>
                </b-row>
                <b-row align-h="between">
                  <b-col class="text-left text-bold">{{ $t('account.verify_account.company_phone') }}</b-col>
                  <b-col class="text-right">{{ data?.company_phone }}</b-col>
                </b-row>
                <b-row align-h="between">
                  <b-col class="text-left text-bold">{{ $t('account.verify_account.county') }}</b-col>
                  <b-col class="text-right">
                    {{ data?.address?.country === 'th' ? $t('account.verify_account.thai') : '-' }}
                  </b-col>
                </b-row>
                <b-row align-h="between">
                  <b-col cols="2" class="text-left text-bold">{{ $t('subscription.checkout.address') }}</b-col>
                  <b-col class="text-right">{{ changeFormatAddress(data?.address) }}</b-col>
                </b-row>
              </div>
              <hr class="hr-dash"/>
              <div v-if="data?.customer_type === 'personal'">
                <div class="text-bold">{{ $t('account.verify_account.id_card_file') }}</div>
                <div class="m-2">
                  <preview-img-or-pdf-component :img-url="data.files.id_card" :close-icon="false"/>
                </div>
              </div>
              <div v-else>
                <div v-if="data?.files?.company_cert">
                  <div class="text-bold">{{ $t('account.verify_account.company_cert_file') }}</div>
                  <div class="m-2">
                    <preview-img-or-pdf-component :img-url="data.files.company_cert" :close-icon="false"/>
                  </div>
                </div>
                <div v-if="data?.files?.vat_cert">
                  <div class="text-bold mt-2">{{ $t('account.verify_account.vat_cert_file') }}</div>
                  <div class="m-2">
                    <preview-img-or-pdf-component :img-url="data.files.vat_cert" :close-icon="false"/>
                  </div>
                </div>
              </div>
              <hr class="hr-dash"/>
              <div>
                <div class="text-bold">{{ $t('account.verify_account.receive_notification') }}</div>
                <div>
                  <b-form-checkbox-group
                      :options="notificationChannelsList"
                      v-model="data.notification_channels"
                      disabled
                      name="flavour-1"
                  />
                </div>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="info-account-empty">
              <div>
                <h5>{{ $t('account.verify_account.info_in_system') }}</h5>
              </div>
              <hr class="hr-dash"/>
              <div>
                <b-row align-h="between">
                  <b-col class="text-left text-bold">{{ $t('account.verify_account.full_name') }}</b-col>
                  <b-col class="text-right">{{ userInfo?.first_name }} {{ userInfo?.last_name }}</b-col>
                </b-row>
                <b-row align-h="between">
                  <b-col class="text-left text-bold">{{ $t('account.verify_account.phone') }}</b-col>
                  <b-col class="text-right">{{ userInfo?.phone }}</b-col>
                </b-row>
                <b-row align-h="between">
                  <b-col cols="2" class="text-left text-bold">{{ $t('account.verify_account.email') }}</b-col>
                  <b-col class="text-right">{{ userInfo?.email }}</b-col>
                </b-row>
              </div>
              <hr class="hr-dash"/>
              <div>
                <div class="text-primary text-bold">{{ $t('account.verify_account.inspection_results') }}</div>
                <div v-if="data.status === 'verified'" class="verify-status-box-verified">
                  {{ $t('account.verify_account.verified_status') }}
                </div>
              </div>
              <div class="info-account mt-3">
                <b-row align-v="center">
                  <b-col>
                    <h5 class="mt-2">{{ $t('common.additional_docs') }}</h5>
                  </b-col>
                  <b-col class="text-right">
                    <div v-if="!add_new_file">
                      <b-button
                          variant="primary"
                          @click="changeAddFileOthers"
                          :disabled="data?.files?.others.length>4"
                      >
                        <i class="fas fa-plus font-9 mr-1"></i>
                        {{ $t("common.attach_new_file") }}
                      </b-button>
                    </div>
                    <div v-else class="d-flex justify-content-end">
                      <b-button
                          variant="dark"
                          @click="cancelUploadFile"
                      >
                        {{ $t("button.cancel") }}
                      </b-button>
                      <b-button
                          variant="primary"
                          class="ml-1"
                          :disabled="add_new_file && files.others <= 0"
                          @click="uploadFileOthers"
                      >
                        {{ $t("button.save") }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <hr class="hr-dash"/>
                <div v-if="add_new_file">
                  <preview-img-or-pdf-component @input="onUploadFileOthers" :img-url="files?.others"
                                                :img-add="require('@/assets/images/icon/upload-file.svg')"
                                                accept="image/png, image/jpeg, .pdf"
                                                :text-upload="$t('common.uploadTitle')"/>
                  <div class="mt-2 text-info font-9 text-center">
                    {{ $t('account.verify_account.upload_id_card_file_info_cintent') }}
                  </div>
                  <hr class="hr-dash"/>
                </div>
                <div v-else>
<!--                  Hide date-->
<!--                  <div class="d-flex">-->
<!--                    <div class="mr-2">{{ $t('common.updated_file') }}</div>-->
<!--                    <div>-->
<!--                      {{ changeDateFormat(data.updatedAt) }}-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="display-other-files">
                    <div v-for="(item, index) in data?.files?.others" :key="index" class="ellipsis-text">
                      <a :href="item" target="_blank"> {{ item }} </a>
<!--                      <preview-img-or-pdf-component :img-url="item" :close-icon="false"/>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<script>
import PreviewImgOrPdfComponent from "@/components/common/previewImgOrPdfComponent.vue";
import functionCommon from "@/common/functions";
import UploadFileSignatureComponent from "@/components/common/uploadFileSignatureComponent.vue";
import UploadImageComponent from "@/components/common/uploadImg.vue";
import uploadImg from "@/components/common/uploadImg.vue";
import profileApi from "@/repository/profileApi";
import alert from "@/common/alert";

export default {
  name: "verifyInfo",
  components: {uploadImg, UploadImageComponent, UploadFileSignatureComponent, PreviewImgOrPdfComponent},
  props: {
    data: {
      type: Object
    },
    userInfo: {
      type: Object
    }
  },
  data() {
    return {
      notificationChannelsList: [
        {text: 'SMS', value: 'sms'},
        {text: 'E-mail', value: 'email'},
      ],
      add_new_file: false,
      files: {
        others: null
      }
    }
  },
  methods: {
    closePopup() {
      this.$bvModal.hide('modal-verify-info')
      this.cancelUploadFile()
    },
    changeFormatAddress(data) {
      const address = data?.address ? data?.address : ''
      const sub_district = data?.sub_district ? 'แขวง' + data?.sub_district : ''
      const district = data?.district ? 'เขต' + data?.district : ''
      const province = data?.province ? data?.province : ''
      const zipcode = data?.zipcode ? data?.zipcode : ''
      return address + ' ' + sub_district + ' ' + district + ' ' + province + ' ' + zipcode
    },
    changeDateFormat(date) {
      return functionCommon.changeDateNotTimeFormat(date)
    },
    changeAddFileOthers() {
      this.add_new_file = true
    },
    onUploadFileOthers(value) {
      this.files.others = value
    },
    uploadFileOthers() {
      profileApi.uploadFileOthers(this.files).then(resp => {
        if (!!resp) {
          alert.updateSuccess(this)
          this.cancelUploadFile()
          this.$emit('update-file-success', true)
        } else {
          alert.updateFailed(this, resp?.message)
        }
      }).catch(err => {
        alert.updateFailed(this, err)
      })
    },
    cancelUploadFile() {
      this.add_new_file = false
      this.files.others = null
    }
  }
}
</script>
<style scoped>
.info-account {
  background: rgba(242, 248, 255, 1);
  padding: 1rem;
  border-radius: 10px;
}

.info-account-empty {
  padding: 1rem;
  border-radius: 10px;
}

.hr-dash {
  border-top: 1px dashed rgba(197, 209, 222, 1);
}

.img-info-box {
  border-radius: 10px;
  background: rgba(231, 252, 245, 1);
  border: 1px solid rgba(6, 127, 109, 1);
  height: 137px;
  margin-top: .5rem;
}

.img-info-box::v-deep embed {
  border-radius: 10px;
  border: 0;
  height: -webkit-fill-available;
  object-fit: contain;

}

.verify-status-box-verified {
  border-radius: 10px;
  border: 1px solid rgba(233, 233, 233, 1);
  background: #E7FCF5;
  padding: 14px 24px;
}

.display-other-files {
  min-height: 20rem;
  max-height: 34rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ellipsis-text{
  text-overflow: ellipsis;
  max-width: 310px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  padding: 6px 6px;
  background: rgba(231, 252, 245, 1);
  border: 1px solid rgba(233, 233, 233, 1);
  border-radius: 4px;
}
</style>